import {
    SearchProxy,
    getPinUserCacheName,
    searchWordStat,
    BigBigWork,
    NetError,
    waitTime,
    statsDzActionClick
} from "bbw-common" ;
import { checkLogin } from "@scripts/util" ;
import Axios, {AxiosError, isCancel} from "axios" ;

export default {
    data: function () {
        return {
            /** 是否活跃中 */
            active: false,
            /** 搜索结果列表 **/
            items: [],
            /** 当前页数  **/
            pageNumber: 1,
            /** 每次翻页增量 */
            pageStep: 2,
            /** 每页条数 **/
            pageSize: 20,
            /** 是否在加载中 **/
            isLoading: true,
            /** 是否全部搜索完毕 **/
            isSearchEnd: false,
            /** 是否网络发生错误(如果有错误值就是错误) **/
            isSearchError: null,
            /** 瀑布流设置 **/
            masonryConfig: {
                gridWidth: 236,
                gap: 16,
                masonryWidth: `auto`
            },
            /** 最大重试次数 */
            maxRetryTimes: 3,
            /** 统计来源 */
            resource: `其他`,
            /** axios取消函数队列 */
            cancelTokens: new Set (),
            /** 非VIP用户能查看的搜索结果数量 */
            noVipResultNumber: 75,
            /** 非VIP用户超过搜索条数则显示底部VIP遮罩 */
            noVipMaskBottom: false,
            // 在错误401的情况况下，超过此数字展示VIP遮罩
            showNoVipMaskNum: 24,
            refreshID: 0,
            // 搜索用户类型，对应localstorage的 pinUser (1:搜索; 1:推荐 )
            searchType: 1,
            // 首次载入是否完毕
            firstLoadReady: false
        } ;
    },
    methods: {
        onPageLeave () {
            // 取消当页所有请求
            this.cancelTokens.forEach ( ( cancel ) => {
                cancel () ;
            } ) ;
            this.cancelTokens.clear () ;
            this.isLoading = false ;
        },
        isShow ( val ) {
            this.isToggleMultiDevice = val ;
        },
        /** 搜索 重置 **/
        resetSearch: function () {
            this.retryTimes = 0 ;
            this.isSearchError = null ;
            this.pageNumber = 1 ;
            this.isSearchEnd = false ;
            this.isLoading = true ;
            this.firstLoadReady = false;
            this.items = [] ;
            this.cancelTokens.forEach ( ( cancel ) => {
                cancel () ;
            } ) ;
            this.cancelTokens.clear () ;
            this.noVipMaskBottom = false ;
            console.log ( `this.$route.query?.kw`, this.$route.query?.kw ) ;
            // eslint-disable-next-line no-unused-expressions
            // 统计提到获得搜索结果后
            // this.$route.query?.kw ? searchWordStat ( {
            //     action_uid: this.$store.state.UserInfo.id,
            //     remain_vip: this.$store.state.UserInfo.vipDays, // 操作用户剩余VIP天数
            //     search_word: this.$route.query?.kw,
            //     search_type: ``,
            //     search_size: ``,
            //     search_form: ``,
            //     search_person: ``,
            //     search_sex: ``,
            //     search_age: ``,
            //     search_color: ``,
            //     search_copyright: ``,
            //     pin_from_page: `PL`,
            //     from_page_url: `https://www.${process.env.PINLITE_DOMAIN}/tupian`,
            //     action_source: `pc`
            // } ) : `` ;
        },
        /** 首屏加载 */
        async firstLoad ( retry = 0 ) {
            try {
                console.log ( `firstLoad` ) ;
                const [err, res] = await this.loadHandle ( null, true ) ;
                if ( err ) throw err ;
                const load = async () => {
                    if ( this.isSearchEnd ) return ;
                    if ( this.$refs.masonry.$el.clientHeight <= window.innerHeight ) {
                        const [err, res] = await this.loadHandle ( null, true ) ;
                        if ( err ) throw err ;
                        this.refreshID && ( this.refreshID = new Date ().getTime () ) ;
                        await load () ;
                    }
                } ;
                await load () ;
            } catch ( e ) {
                console.log ( `firstLoad`, e ) ;
                throw e;
                // // 签名不通过的或鉴黄不通过，主动取消的，直接停止加载
                // if ( [`SIGN_ERROR`, `word_ERROR`].includes ( e.message ) ) {
                //     throw e ;
                // } else if ( isCancel ( e ) ) {
                //     // 主动取消，什么事也不做
                //     throw e ;
                // } else if ( retry < this.maxRetryTimes ) {
                //     // 其他错误，尝试重新加载
                //     // 切换一次节点试试
                //     this.$store.commit ( `PinUser/switchPinNode` ) ;
                //     waitTime ( 300 ).then ( () => {
                //         this.firstLoad ( retry + 1 ) ;
                //     }) ;
                // } else {
                //     throw e ;
                // }
            }
        },
        async loadHandleErr ( e?:Event, focus:boolean = false ){
            const [err, res] = await this.loadHandle ( e, focus ) ;
            if ( err ) throw err;
        },
        /** 滚动事件，返回错误版 **/
        loadHandle: async function ( e?:Event, focus:boolean = false ) {
            const _self = this ;
            let end = false ;
            const errors = [];
            if ( ( !focus && this.isLoading ) || this.isSearchEnd ) return Promise.resolve ( [null, true] ) ;
            try {
                this.isLoading = true ;
                const items = [] ;
                for ( let i = _self.pageNumber ; i < _self.pageNumber + _self.pageStep ; i++ ) {
                    const screenItems = [] ;
                    const [err, item] = await _self.loadMore ( i );
                    if ( err ) {
                        errors.push(err);
                        break;
                    }
                    if ( item.length === 0 ) {
                        end = true ;
                        break ;
                    } else {
                        for ( const itemElement of item ) {
                            // 去重
                            const repeatItem = _self.items.find ( existItem => existItem.id === itemElement.id ) ;
                            const repeatItem1 = screenItems.find ( existItem => existItem.id === itemElement.id ) ;
                            if ( repeatItem || repeatItem1 ) {
                                continue ;
                            } ;
                            screenItems.push ( itemElement ) ;
                        }
                        _self.items.push ( ...screenItems ) ;
                        items.push ( ...screenItems ) ;
                        console.log ( screenItems ) ;
                    }
                }
                // 有错误
                if ( errors.length ) {
                    throw errors[0];
                }
                // 数据全重 结束搜索
                if ( items.length === 0 || errors.length > 0 ) {
                    end = true ;
                }
                if ( end ) {
                    this.isSearchEnd = true ;
                } else {
                    this.pageNumber += this.pageStep ;
                    this.retryTimes = 0 ;
                }
                this.isLoading = false ;
                // 去重后数量不足7再次调接口
                if ( !end && items.length < 7 ) {
                    const [err, res] = await this.loadHandle ( e, focus ) ;
                    if ( err ) {
                        throw err ;
                    }
                }
                this.isLoading = false ;
                this.firstLoadReady = true;
                return [null, true] ;
            } catch ( e ) {
                this.isLoading = false ;
                this.firstLoadReady = true;
                if ( !isCancel ( e ) ) {
                    this.isSearchError = e ;
                    this.isSearchEnd = true ;
                }
                return [e, null]
            }
        },
        /** 滚动事件 **/
        // loadHandle: async function ( e?:Event, focus:boolean = false ) {
        //     const _self = this ;
        //     let end = false ;
        //     if ( ( !focus && this.isLoading ) || this.isSearchEnd ) return Promise.resolve ( true ) ;
        //     try {
        //         this.isLoading = true ;
        //         const items = [] ;
        //         for ( let i = _self.pageNumber ; i < _self.pageNumber + _self.pageStep ; i++ ) {
        //             const screenItems = [] ;
        //             const item = await _self.loadMore ( i ).catch ( e => { throw e ; } ) ;
        //             if ( item.length === 0 ) {
        //                 end = true ;
        //                 break ;
        //             } else {
        //                 for ( const itemElement of item ) {
        //                     // 去重
        //                     const repeatItem = _self.items.find ( existItem => existItem.id === itemElement.id ) ;
        //                     const repeatItem1 = screenItems.find ( existItem => existItem.id === itemElement.id ) ;
        //                     if ( repeatItem || repeatItem1 ) {
        //                         continue ;
        //                     } ;
        //                     screenItems.push ( itemElement ) ;
        //                 }
        //                 _self.items.push ( ...screenItems ) ;
        //                 items.push ( ...screenItems ) ;
        //                 console.log ( screenItems ) ;
        //             }
        //         }
        //         // 数据全重 结束搜索
        //         if ( items.length === 0 ) {
        //             end = true ;
        //         }
        //         if ( end ) {
        //             this.isSearchEnd = true ;
        //         } else {
        //             this.pageNumber += this.pageStep ;
        //             this.retryTimes = 0 ;
        //         }
        //         this.isLoading = false ;
        //         // 去重后数量不足7再次调接口
        //         if ( !end && items.length < 7 ) {
        //             await this.loadHandle () ;
        //         }
        //         return true ;
        //     } catch ( e ) {
        //         if ( e.message === `word_ERROR` ) return ;
        //         this.isLoading = false ;
        //         if ( !isCancel ( e ) ) {
        //             this.isSearchError = e ;
        //             this.isSearchEnd = true ;
        //         }
        //         throw e ;
        //     } finally {
        //         this.isLoading = false ;
        //     }
        // },
        loadMore: async function ( pageNumber, retry:number = 0 ) {
            try {
                // 非VIP只显示4页数据
                if ( !this.$store.getters[`UserInfo/isVip`] && this.items.length >= this.noVipResultNumber ) {
                    this.noVipMaskBottom = true ;
                    return Promise.resolve ( [null, []] ) ;
                }
                if ( !this.fetchData ) {
                    throw Error ( `组件内必须提供fetchData函数` ) ;
                }
                const [err, res] = await this.fetchData ( pageNumber, false );
                if ( err ) {
                    const [e, state] = await this.handleError ( err, this.searchType ) ;
                    if ( state === `retry` && retry < 1 ) {
                        return await this.loadMore ( pageNumber, retry + 1 );
                    } else {
                        return [e, []] ;
                    }
                }
                return [null, res];
            } catch ( e ) {
                return [e, []]
            }
        },
        /** 加载下一页数据 **/
        // loadMore: function ( pageNumber, retry:number = 0 ) {
        //     // 非VIP只显示4页数据
        //     if ( !this.$store.getters[`UserInfo/isVip`] && this.items.length >= this.noVipResultNumber ) {
        //         this.noVipMaskBottom = true ;
        //         return Promise.resolve ( [] ) ;
        //     }
        //     if ( !this.fetchData ) {
        //         throw Error ( `组件内必须提供fetchData函数` ) ;
        //     }
        //     // 调用页面fetchData函数获取下一页数据
        //     return this.fetchData ( pageNumber, false ).then ( result => {
        //         const items = result ;
        //         if ( typeof items === `undefined` ) {
        //             throw new Error ( `没有搜索结果` ) ;
        //         }
        //         if ( items.length === 0 ) {
        //             return Promise.resolve ( [] ) ;
        //         } else {
        //             return Promise.resolve ( items ) ;
        //         }
        //     } ).catch ( e => {
        //         // 签名不通过的或鉴黄不通过，主动取消的，直接停止加载
        //         if ( e.message === `SIGN_ERROR` || e.message === `word_ERROR` ) {
        //             throw e ;
        //         } else if ( isCancel ( e ) ) {
        //             // 主动取消，什么事也不做
        //             throw e ;
        //         } else if ( retry < this.maxRetryTimes ) {
        //             // 其他错误，尝试重新加载
        //             // 切换一次节点试试
        //             this.$store.commit ( `PinUser/switchPinNode` ) ;
        //             return this.loadMore ( pageNumber, retry + 1 ) ;
        //         } else {
        //             throw e ;
        //         }
        //     } ) ;
        // },
        // 处理错误, 返回[error, status]
        async handleError ( e, pinUserType ) {
            if ( isCancel ( e ) || !e ) return [null, ``] ;
            let err = e;
            this.isSearchError = e ;
            if ( typeof err === `string` ) {
                err = new Error ( `err:` + e ) ;
                return [err, `end`] ;
            }
            let status = -1001 ;
            if ( err.isAxiosError ) {
                status = e?.response?.status ;
            } else if ( err?.name === `NetError` ) {
                status = e.res?.data?.status ?? e.res?.status ;
            }
            if ( [400, 403].includes ( status ) ) {
                const [error, res] = await this.handlePinUserCrash ( pinUserType ) ;
                if ( error ) {
                    err = error ;
                }
                return [null, `retry`] ;
            } else if ( [401].includes ( status ) ) {
                // err.message = "word_ERROR";
                // await this.handleSign( { force: true } ) ;
                // if ( this.items.length >= 24 ) {
                //     this.noVipMaskBottom = true ;
                //     return [err, `end`] ;
                // } else {
                //     return [err, `end`] ;
                // }
                if ( this.items.length >= this.showNoVipMaskNum ) {
                    this.noVipMaskBottom = true ;
                }
                return [err, `end`] ;
            } else if ( [406, 423].includes ( status ) ) {
                this.onSensitiveWordsFilterError ( ) ;
                // err.message = "word_ERROR";
                return [err, `end`] ;
            } else if ( [451].includes ( status ) ) {
                // err.message = "SIGN_ERROR";
                // page loading failed
                return [err, `end`] ;
            } else {
                if ( err.isAxiosError && !( err as AxiosError ).response ) {
                    // 切换一次节点试试
                    this.$store.commit ( `PinUser/switchPinNode` ) ;
                    return [err, `retry`] ;
                }
            }
            return [err, `end`] ;
        },
        onSensitiveWordsFilterError (){
            console.log('onSensitiveWordsFilterError');
        },
        /** 当pinuser的cookie失效后调用并抛出错误 */
        async handlePinUserCrashError ( type:number = 1 ) {
            const [err, res ] = await this.handlePinUserCrash ( type ) ;
            if ( err ) throw err;
        },
        /** 当pinuser的cookie失效后调用 */
        async handlePinUserCrash ( type:number = 1 ) {
            const disableRefreshPinUser = localStorage.getItem (`disableRefreshPinUser` ) ;
            if ( disableRefreshPinUser ) {
                const code = 400 ;
                const err = new NetError (`用户失效`, { status: code, data: { status: code } } ) ;
                this.isSearchError = err ;
                return [err, null] ;
            }
            this.$store.commit ( `PinUser/setName`, { type: type, name: `` } ) ;
            localStorage.removeItem ( getPinUserCacheName ( type ) ) ;
            const [pinNameErr, pinName] = await this.$store.dispatch ( `PinUser/FETCH_PIN_NAME`, { type: type } );
            if ( pinNameErr || !pinName ) {
                this.$store.commit ( `PinUser/setName`, { type: type, name: `` } ) ;
                localStorage.removeItem ( getPinUserCacheName ( type ) ) ;
                this.isSearchEnd = true ;
                this.loading = false ;
                const e = pinNameErr || new Error ( `获取用户名失败` );
                this.isSearchError = e ;
                return [e, pinName] ;
            }
            return [pinNameErr, pinName] ;
        },
        /** 获取签名(如果签名不成功，检查是否登录) */
        async handleSign ( options ) {
            try {
                const { force } = options || {} ;
                // 获取令牌，如果出错就报错
                const [signErr, sign] = await this.$store.dispatch ( `PinUser/FETCH_SIGN`, { force: force } ) ;
                if ( signErr || !sign ) {
                    this.isSearchEnd = true ;
                    this.loading = false ;
                    this.isSearchError = signErr ;
                    await this.$store.dispatch ( `UserInfo/FETCH_CHECK_BROWSER_UNIQUE`, { source: this.source } ).then ( res => {
                        if ( res.status !== 200 || ( res.data.status + `` ) !== `200` ) {
                            this.$refs.loginAuth.onLoginFail ( true ) ;
                        }
                    } ) ;
                    throw new Error ( `签名获取失败` ) ;
                }
                return sign ;
            } catch ( e ) {
                throw e ;
            }
        }
    },
    computed: {
        errorCode ( ) {
            if ( isCancel ( this.isSearchError ) || !this.isSearchError ) {
                return 200 ;
            }
            let code = -1001 ;
            if ( this.isSearchError.isAxiosError ) {
                code = this.isSearchError?.response?.status ?? -1000 ;
            } else if ( this.isSearchError.name === `NetError` ) {
                code = this.isSearchError?.res?.data?.status ?? -1002 ;
            }
            return code ;
        },
        /** 搜索状态 **/
        searchState: function () {
            if ( this.isLoading || !this.firstLoadReady ) return `LOADING` ;
            // 报错
            if ( this.isSearchError && this.items.length === 0 ) {
                if ( [401, 406, 423].includes ( this.errorCode ) ) {
                    return `END_NORESULT` ;
                }
                if ( this.$route.name === `home` && !this.keyword ) {
                    // 首页推荐内容特别提示
                    if ( [403, 400, 500].includes ( this.errorCode ) ) {
                        return `HOME_ERROR` ;
                    } else {
                        return `ERROR` ;
                    }
                } else {
                    return `ERROR` ;
                }
            }
            // 加载中
            if ( !this.isSearchEnd || this.cancelTokens.length > 0 ) return `LOADING` ;
            if ( this.isSearchEnd ) {
                // 没有结果
                if ( this.items.length === 0 ) {
                    return `END_NORESULT` ;
                } else if ( this.items.length <= 20 ) {
                    return `END_LESSRESULT` ;
                } else {
                    return `END` ;
                }
            } else {
                return `LOADING` ;
            }
        },
        /** 非VIP底部遮罩是否需要显示， true:显示普通的search end; false:显示非VIP遮罩 */
        bottomState () {
            if ( !this.$store.getters[`UserInfo/userReady`] ) {
                return true ;
            } else {
                return !this.noVipMaskBottom ;
            }
        }
    },
    watch: {
        searchState: {
            handler: function ( newVal, oldVal ) {
                console.log ( `searchState`, newVal ) ;
                if ( newVal === `END_LESSRESULT` ) {
                    // 结果少，发统计
                    statsDzActionClick ( {
                        store: this.$store,
                        action_type: `pin图少`,
                        source: `pc`,
                        from_page: window.location.href,
                        remark: ``
                    } ) ;
                }
            }
        },
        /** 代理节点变化的时候，重新取图片 */
        "$store.state.PinUser.proxyType": function ( newVal, oldVal ) {
            if ( newVal !== oldVal ) {
                this.items.forEach ( item => {
                    const proxy = SearchProxy.randomProxy ( ) ;
                    item.proxy = proxy ;
                } ) ;
                if ( this.$refs.masonry ) {
                    this.$refs.masonry.$children.forEach ( gridItem => {
                        gridItem.onItemChange () ;
                    } ) ;
                }
            }
        }
    }
} ;
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
