var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.SearchState === "ERROR" && _vm.$store.getters["UserInfo/userReady"]
      ? _c("div", { class: [_vm.$style.error, "font-medium"] }, [
          _c("i"),
          _vm._v(" "),
          _c("p", [
            _vm._v("Pinlite极速版网页暂时维护中"),
            _c("span", { class: _vm.$style["errorStatus"] }, [
              _vm._v(
                _vm._s(
                  "" + (_vm.errorStatus ? "，状态码" + _vm.errorStatus : "")
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "qrcode",
            attrs: {
              src: _vm.ServiceQR,
              alt: "客服二维码",
              "aria-label": "客服二维码",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("扫码添加大作客服")]),
          _vm._v(" "),
          _c("span", [_vm._v("即时获取网站维护信息")]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.SearchState === "HOME_ERROR" && _vm.$store.getters["UserInfo/userReady"]
      ? _c("div", { class: [_vm.$style.error, _vm.$style.home] }, [
          _c("i"),
          _vm._v(" "),
          _c("p", [
            _vm._v("哎呀，推荐内容临时维护中,"),
            _c("br"),
            _vm._v("  请"),
            _c("span", { class: _vm.$style.b }, [_vm._v("直接使用搜索功能")]),
            _vm._v("。"),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "qrcode",
            attrs: {
              src: _vm.ServiceQR,
              alt: "客服二维码",
              "aria-label": "客服二维码",
            },
          }),
          _vm._v(" "),
          _c("span", { class: _vm.$style.desc }, [_vm._v("扫码添加大作客服")]),
          _vm._v(" "),
          _c("span", { class: _vm.$style.desc }, [
            _vm._v("即时获取网站维护信息"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.SearchState === "LOADING"
      ? _c("div", { class: _vm.$style.loading }, [_c("Loading")], 1)
      : _vm._e(),
    _vm._v(" "),
    _vm.SearchState === "END_NORESULT" && _vm.Keyword
      ? _c("div", { class: _vm.$style.noResult }, [
          _c("i"),
          _vm._v(" "),
          _c("p", [
            _c(
              "svg",
              {
                class: _vm.$style.pinlitelogo,
                attrs: {
                  width: "32",
                  height: "32",
                  viewBox: "0 0 32 32",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
                  _c("circle", {
                    attrs: { fill: "#FFF", cx: "16", cy: "16", r: "16" },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M19.75 31.558v.339h-1.922a15.944 15.944 0 001.923-.34zm2.283-.734v1.073H20.67v-.59c.463-.14.918-.302 1.363-.483zm2.266-1.142v.954c0 .105.1.176.302.212h.332v1.049h-1.13c-.454-.104-.724-.375-.81-.815v-.687c.447-.218.883-.456 1.306-.713zm.705-.454a16.078 16.078 0 002.135-1.742c1.396.012 2.126.873 2.19 2.582h-3.06c.102.637.413.956.934.956.353 0 .596-.161.727-.484h1.34c-.309.973-.998 1.46-2.067 1.46-1.359 0-2.249-1.016-2.268-1.932a3.923 3.923 0 01.069-.84zm1.3.034h1.655c-.098-.564-.374-.845-.828-.845-.454 0-.73.281-.826.845zM13.08 31.734c.312-.069.618-.146.917-.232.227-2.599.87-6.054 1.928-10.368.958.404 2.106.606 3.442.606 2.118 0 3.933-.492 5.446-1.476 2.798-1.79 4.197-4.44 4.197-7.946 0-2.674-.983-4.641-2.95-5.902-1.966-1.287-4.865-1.93-8.698-1.93-3.832 0-7.185.782-10.06 2.346-2.873 1.564-4.31 3.632-4.31 6.205 0 1.438.44 2.598 1.323 3.481.857.883 1.765 1.387 2.723 1.514.53-.959.845-1.892.946-2.8-.58-.152-1.148-.442-1.702-.87-.53-.455-.794-1.035-.794-1.741 0-1.387 1.033-2.712 3.1-3.973 2.068-1.261 4.665-1.892 7.791-1.892 2.395 0 4.122.454 5.181 1.362 1.084.883 1.626 2.321 1.626 4.314 0 1.084-.126 2.119-.378 3.102-.227.959-.73 1.854-1.512 2.687-.782.832-1.778 1.248-2.988 1.248-.706 0-1.336-.113-1.891-.34 1.21-4.894 1.815-7.731 1.815-8.513 0-.808-.063-1.337-.189-1.59-.378-.126-.958-.189-1.74-.189-1.991 0-3.655.391-4.991 1.173l-.038.265c.378.429.617.757.718.984.126.227.19.58.19 1.06 0 1.513-.656 4.78-1.967 9.799-.89 3.472-1.501 6.123-1.836 7.953C3.388 27.363 0 22.077 0 16 0 7.163 7.163 0 16 0s16 7.163 16 16c0 4.507-1.863 8.578-4.861 11.486h-.03c-1.346 0-1.922.82-2.105 1.742-.231.157-.466.309-.705.454v-1.224h.634v-.83h-.634V26.24h-1.306v1.389h-.456v.829h.456v1.937c-.315.153-.635.296-.96.429v-3.195H20.67v3.679c-.303.092-.61.175-.92.25v-.9h-2.368v1.239h.446a16.172 16.172 0 01-4.749-.163zm2.903-5.808v5.97h1.4v-5.97h-1.4zm5.39 1.373a.846.846 0 100-1.692.846.846 0 000 1.692z",
                      fill: "#767676",
                    },
                  }),
                ]),
              ]
            ),
            _vm._v("无"),
            _c("span", { class: _vm.$style.bold }, [
              _vm._v("“" + _vm._s(_vm.Keyword) + "”"),
            ]),
            _vm._v("相关图片，"),
          ]),
          _vm._v(" "),
          _c("p", { class: _vm.$style.change_text }, [
            _vm._v("换个搜索词再试试!"),
          ]),
          _vm._v(" "),
          _c("p", { class: _vm.$style.or }, [_vm._v("或")]),
          _vm._v(" "),
          _c(
            "p",
            { class: _vm.$style.bigwork_serach, on: { click: _vm.goToTuYi } },
            [
              _c("span", [_vm._v("试试")]),
              _c("span", { class: _vm.$style.goto_serach }, [
                _vm._v("图意搜索"),
              ]),
              _c("span", [_vm._v("~")]),
            ]
          ),
          _vm._v(" "),
          _c("p", { class: _vm.$style.notfind }, [
            _vm._v("找不到图？添加大作助手"),
          ]),
          _vm._v(" "),
          _c("p", { class: _vm.$style.findImage }, [
            _vm._v("我们可以人工免费帮您找图!"),
          ]),
          _vm._v(" "),
          _c("img", {
            class: _vm.$style.image,
            staticStyle: { width: "120px" },
            attrs: { src: _vm.CDN_XCX_PATH + "/p/code/code2x_2.png" },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showEnd
      ? _c("div", { class: _vm.$style.searchEnd }, [
          !_vm.isNewSearchEnd
            ? _c(
                "svg",
                {
                  class: _vm.$style.pinlitelogo,
                  attrs: {
                    width: "32",
                    height: "32",
                    viewBox: "0 0 32 32",
                    xmlns: "http://www.w3.org/2000/svg",
                    "svg-inline": "",
                    role: "presentation",
                    focusable: "false",
                    tabindex: "-1",
                  },
                },
                [
                  _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
                    _c("circle", {
                      attrs: { fill: "#FFF", cx: "16", cy: "16", r: "16" },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M19.75 31.558v.339h-1.922a15.944 15.944 0 001.923-.34zm2.283-.734v1.073H20.67v-.59c.463-.14.918-.302 1.363-.483zm2.266-1.142v.954c0 .105.1.176.302.212h.332v1.049h-1.13c-.454-.104-.724-.375-.81-.815v-.687c.447-.218.883-.456 1.306-.713zm.705-.454a16.078 16.078 0 002.135-1.742c1.396.012 2.126.873 2.19 2.582h-3.06c.102.637.413.956.934.956.353 0 .596-.161.727-.484h1.34c-.309.973-.998 1.46-2.067 1.46-1.359 0-2.249-1.016-2.268-1.932a3.923 3.923 0 01.069-.84zm1.3.034h1.655c-.098-.564-.374-.845-.828-.845-.454 0-.73.281-.826.845zM13.08 31.734c.312-.069.618-.146.917-.232.227-2.599.87-6.054 1.928-10.368.958.404 2.106.606 3.442.606 2.118 0 3.933-.492 5.446-1.476 2.798-1.79 4.197-4.44 4.197-7.946 0-2.674-.983-4.641-2.95-5.902-1.966-1.287-4.865-1.93-8.698-1.93-3.832 0-7.185.782-10.06 2.346-2.873 1.564-4.31 3.632-4.31 6.205 0 1.438.44 2.598 1.323 3.481.857.883 1.765 1.387 2.723 1.514.53-.959.845-1.892.946-2.8-.58-.152-1.148-.442-1.702-.87-.53-.455-.794-1.035-.794-1.741 0-1.387 1.033-2.712 3.1-3.973 2.068-1.261 4.665-1.892 7.791-1.892 2.395 0 4.122.454 5.181 1.362 1.084.883 1.626 2.321 1.626 4.314 0 1.084-.126 2.119-.378 3.102-.227.959-.73 1.854-1.512 2.687-.782.832-1.778 1.248-2.988 1.248-.706 0-1.336-.113-1.891-.34 1.21-4.894 1.815-7.731 1.815-8.513 0-.808-.063-1.337-.189-1.59-.378-.126-.958-.189-1.74-.189-1.991 0-3.655.391-4.991 1.173l-.038.265c.378.429.617.757.718.984.126.227.19.58.19 1.06 0 1.513-.656 4.78-1.967 9.799-.89 3.472-1.501 6.123-1.836 7.953C3.388 27.363 0 22.077 0 16 0 7.163 7.163 0 16 0s16 7.163 16 16c0 4.507-1.863 8.578-4.861 11.486h-.03c-1.346 0-1.922.82-2.105 1.742-.231.157-.466.309-.705.454v-1.224h.634v-.83h-.634V26.24h-1.306v1.389h-.456v.829h.456v1.937c-.315.153-.635.296-.96.429v-3.195H20.67v3.679c-.303.092-.61.175-.92.25v-.9h-2.368v1.239h.446a16.172 16.172 0 01-4.749-.163zm2.903-5.808v5.97h1.4v-5.97h-1.4zm5.39 1.373a.846.846 0 100-1.692.846.846 0 000 1.692z",
                        fill: "#767676",
                      },
                    }),
                  ]),
                ]
              )
            : _vm.translatedWords.length
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("没更多搜索结果啦~试试用英文搜索:")]),
                  _vm._v(" "),
                  _vm._l(_vm.translatedWords, function (item, index) {
                    return _c(
                      "router-link",
                      {
                        key: index,
                        class: _vm.$style.translateWord,
                        attrs: { to: { path: "/tupian", query: { kw: item } } },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              mousedown: function ($event) {
                                return _vm.handleTranslateClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src: require("@images/icon-assistant-magnifier.svg"),
                      alt: "",
                    },
                  }),
                ],
                2
              )
            : _c("div", [
                _c("p", [
                  _vm._v("没更多搜索结果啦~试试用AI赋能的新一代灵感搜索:"),
                  _c(
                    "span",
                    {
                      class: _vm.$style["underline"],
                      on: { click: _vm.handleTuYi },
                    },
                    [_vm._v("图意搜索")]
                  ),
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@images/icon-assistant-magnifier.svg"),
                    alt: "",
                  },
                }),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }