<template>
  <div class="loading wrapper">
    <svg :class="[`rotateAni`,$style.img]" height="40" width="40" viewBox="0 0 24 24" aria-label="Loading homefeed"
         role="img"
    style="fill: #767676">
      <path
          d="M15 10.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m0 6c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m-6-6c-.83 0-1.5-.67-1.5-1.5S8.17 7.5 9 7.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5m0 6c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5M12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0"></path>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Loading"
}
</script>
<style lang=scss module>
@import "assets/styles/params";

.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #767676;

  &::before {
    content: " ";
    display: block;
    float: none;
    clear: both;
  }
}

.img {
  //@extend .svgGray;
  fill: #767676;
}
</style>
