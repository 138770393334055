<template>
    <div class="loading-box">
        <div class="loading-box-icon">
            <slot>
                <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(5 5)" fill="#767676" fill-rule="evenodd"><circle cx="25" cy="5" r="5"/><circle cx="5" cy="5" r="5"/><circle cx="5" cy="25" r="5"/><circle cx="25" cy="25" r="5"/></g></svg>
<!--                <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
<!--                    <g id="loading备份-4" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                        <g id="loading备份" class="loading-fill" style="fill: #767676">-->
<!--                            <g id="编组-6" transform="translate(4.000000, 4.000000)">-->
<!--                                <circle id="2" cx="25" cy="5" r="5"></circle>-->
<!--                                <circle id="1" cx="5" cy="5" r="5"></circle>-->
<!--                                <circle id="4" cx="5" cy="25" r="5"></circle>-->
<!--                                <circle id="3" cx="25" cy="25" r="5"></circle>-->
<!--                            </g>-->
<!--                        </g>-->
<!--                    </g>-->
<!--                </svg>-->
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
    export default {
        name: "Loading"
    }
</script>

<style module lang="scss" src="./Loading.scss"></style>
