var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-box" }, [
    _c(
      "div",
      { staticClass: "loading-box-icon" },
      [
        _vm._t("default", function () {
          return [
            _c(
              "svg",
              {
                attrs: {
                  width: "40",
                  height: "40",
                  viewBox: "0 0 40 40",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      transform: "translate(5 5)",
                      fill: "#767676",
                      "fill-rule": "evenodd",
                    },
                  },
                  [
                    _c("circle", { attrs: { cx: "25", cy: "5", r: "5" } }),
                    _c("circle", { attrs: { cx: "5", cy: "5", r: "5" } }),
                    _c("circle", { attrs: { cx: "5", cy: "25", r: "5" } }),
                    _c("circle", { attrs: { cx: "25", cy: "25", r: "5" } }),
                  ]
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }